// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-layouts-essay-layout-jsx": () => import("./../../../src/components/layouts/essay-layout.jsx" /* webpackChunkName: "component---src-components-layouts-essay-layout-jsx" */),
  "component---src-components-layouts-experience-layout-jsx": () => import("./../../../src/components/layouts/experience-layout.jsx" /* webpackChunkName: "component---src-components-layouts-experience-layout-jsx" */),
  "component---src-components-layouts-poem-layout-jsx": () => import("./../../../src/components/layouts/poem-layout.jsx" /* webpackChunkName: "component---src-components-layouts-poem-layout-jsx" */),
  "component---src-components-layouts-project-layout-jsx": () => import("./../../../src/components/layouts/project-layout.jsx" /* webpackChunkName: "component---src-components-layouts-project-layout-jsx" */),
  "component---src-components-layouts-reading-layout-jsx": () => import("./../../../src/components/layouts/reading-layout.jsx" /* webpackChunkName: "component---src-components-layouts-reading-layout-jsx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-hongkong-jsx": () => import("./../../../src/pages/hongkong.jsx" /* webpackChunkName: "component---src-pages-hongkong-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-japan-201-jsx": () => import("./../../../src/pages/japan-201.jsx" /* webpackChunkName: "component---src-pages-japan-201-jsx" */),
  "component---src-pages-japan-2023-jsx": () => import("./../../../src/pages/japan-2023.jsx" /* webpackChunkName: "component---src-pages-japan-2023-jsx" */),
  "component---src-pages-japan-jsx": () => import("./../../../src/pages/japan.jsx" /* webpackChunkName: "component---src-pages-japan-jsx" */),
  "component---src-pages-japan-oct-2023-jsx": () => import("./../../../src/pages/japan-oct-2023.jsx" /* webpackChunkName: "component---src-pages-japan-oct-2023-jsx" */),
  "component---src-pages-reading-jsx": () => import("./../../../src/pages/reading.jsx" /* webpackChunkName: "component---src-pages-reading-jsx" */),
  "component---src-pages-reading-list-2020-jsx": () => import("./../../../src/pages/reading-list-2020.jsx" /* webpackChunkName: "component---src-pages-reading-list-2020-jsx" */),
  "component---src-pages-reading-list-2021-jsx": () => import("./../../../src/pages/reading-list-2021.jsx" /* webpackChunkName: "component---src-pages-reading-list-2021-jsx" */),
  "component---src-pages-reading-list-2022-jsx": () => import("./../../../src/pages/reading-list-2022.jsx" /* webpackChunkName: "component---src-pages-reading-list-2022-jsx" */),
  "component---src-pages-reading-list-2023-jsx": () => import("./../../../src/pages/reading-list-2023.jsx" /* webpackChunkName: "component---src-pages-reading-list-2023-jsx" */),
  "component---src-pages-reading-list-2024-jsx": () => import("./../../../src/pages/reading-list-2024.jsx" /* webpackChunkName: "component---src-pages-reading-list-2024-jsx" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-writing-jsx": () => import("./../../../src/pages/writing.jsx" /* webpackChunkName: "component---src-pages-writing-jsx" */)
}

